import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/view/login"

Vue.use(Router)

export default new Router({
	// mode: 'history',
	base: __dirname,
	routes: [{
			path: '/',
			name: 'login',
			component: Login
		},
		// {
		// 	path: '/forgetPassword',
		// 	name: 'forgetPassword',
		// 	component: () => import('@/view/forgetPassword')
		// },
		{
			path: '/head',
			name: 'head',
			component: resolve => require(['@/components/head'], resolve),
			children: [
				{
					path: '/index',
					name: 'index',
					component: () => import('@/view/index')
				}
			]
		}
	]
})
