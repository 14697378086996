import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        getToken: true,
        // myInfo:{},
        // leftList:[],
        isLogin: false,
        // tabList:[],
        // regionSelect:[],  //区域下拉列表
    },
    getters:{
        isLogin (state) {  
            if (!state.isLogin) {      
                state.isLogin = JSON.parse(sessionStorage.getItem('isLogin'));   //从sessionStorage中读取状态  
                // state.myInfo = JSON.parse(sessionStorage.getItem('myInfo'));
                // state.leftList = JSON.parse(sessionStorage.getItem('leftList'));
                // state.tabList = JSON.parse(sessionStorage.getItem('tabList'));
            }  
            return state.isLogin
        },
    },
    mutations: {
        // SET_INFO (state,data) {
        //     state.myInfo = Object.assign({}, data);
        //     sessionStorage.setItem('myInfo', JSON.stringify(data));
        //     sessionStorage.setItem("isLogin",true); 
        // },
        // SET_LEFT (state,data) {
        //     state.leftList = Object.assign([], data)
        //     sessionStorage.setItem('leftList', JSON.stringify(data));
        // },
        // SET_TAB (state,data) {
        //     state.tabList = Object.assign([], data)
        //     sessionStorage.setItem('tabList', JSON.stringify(data));
        // },
        // GET_REGION (state,data){
        //     state.regionSelect = Object.assign([], data)
        // },
        CHANGE_TEMP(state,data){
            state.getToken = data
        },  
        SIGN_OUT (state) {   //退出，删除状态  
            sessionStorage.clear();
            // state.myInfo = {}
            // state.leftList = []  
            // state.tabList = []          
            state.getToken = true
            state.isLogin = false  
        }
    },
    actions: {
        // actionUser (context,data) {
        //     context.commit('SET_INFO',data)
        // },
        // actionMuen (context,data) {
        //     context.commit('SET_LEFT',data)
        // },
        // actionTab(context,data) {
        //     context.commit('SET_TAB',data)
        // },
        actionTemp(context,data) {
            context.commit('CHANGE_TEMP',data)
        },
        actionOut(context) {
            context.commit('SIGN_OUT')
        },
        // actionRegion(context,data) {
        //     context.commit('GET_REGION',data)
        // }
    }
    
})
export default store;