<template>
	<div class="login">
        <div class="center">
            <div class="formImg">
                <div class="imgDiv">
                    <img src="../../public/image/logo1.png" class="logo">
                </div>
                <div class="imgText">能源托管收费平台</div>
            </div>
            <div class="form" v-show="temp">
                <div class="formHeard">欢迎登录</div>
                <div class="blue"></div>
                <Input prefix="ios-person" placeholder="用户名" v-model="user" class="myInput" />
                <Input prefix="ios-lock" type="password" placeholder="密码" v-model="password" @keyup.enter.native="login" class="myInput myInput1" />
                <div class="loginBtn" @click="login">登录</div>
            </div>
        </div>
	</div>
</template>

<script>
	import url from '../utils/api.js';
    import { Debounce } from '../utils/debounce.js'
	export default {
		name: 'login',
		data () {
			return {
				user: '',
                password: '',
                temp:true
			}
		},
		mounted() {
            this.common.delete('token');
            this.common.delete('reToken');
            this.common.delete('username');
            this.common.delete('expiresin');
		},
		methods: {
            login:Debounce(function(){
				let arr = {
					'grant_type':'password',
					'username':this.user,
					'password':this.password
				}
				this.$get(url.LOGIN,arr).then(res=>{
                    this.common.set('token', res['access_token']);
                    this.common.set('reToken', res['refresh_token']);
                    this.common.set('expiresin', parseInt(new Date().getTime() / 1000) + res['expires_in'] * 1);
                    this.$get(url.GET_USER_INFO).then(res1=>{
                        if(res1){
                            this.common.set('username', res1.name);
                            this.$Message.success('登录成功!')
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/index'
                                });
                            }, 1000) 
                        }
                    }).catch(error => {
                        this.$Message.error(error.response.data.msg);
                    })
                }).catch(error => {
					this.$Message.error(error.response.data.msg);
                })
            },1000)
		}
	}
</script>
<style lang="less">
    .myInput{
        .ivu-input{
            height:40px;
        }
        .ivu-input-inner-container>.ivu-input{
            height: 40px;
        }
        .ivu-input-prefix>.ivu-icon{
            line-height: 40px;
        }
    }
    .regFrom > .ivu-form-item-label{
        color: #858693 !important;
        font-size: 14px !important;
        padding-right: 0px !important;
    }
</style>
<style scoped lang="less">
	@color:rgba(241, 230, 230,0);
	@left:left;
	@inline_b:inline-block;
	@img:'../../public/image/';
	@blue:#45c8dc;
	.login{
        width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: #fff;
		background-image: url("@{img}beijing.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		overflow: hidden;
		border-color: @color;
	}
	.center{
        width: 1000px;
        height: 370px;
        margin:330px auto 0 auto;
        border-radius: 4px;
        background-color: rgba(0, 10, 42, 0.45);
    }
    .formImg{
        width: 300px;
        height: 100%;
        padding-top: 84px;
        padding-bottom: 100px;
        position: relative;
        top:0;
        left: 153px;
    }
    .imgDiv{
        width: 242px;
        height: 52px;
        padding-left: 40px;
    }
    .logo{
        width: 100%;
        height: 100%;
    }
    .imgText{
        font-family: MicrosoftYaHei-Bold;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        line-height: 234px;
        color: #ffffff;
    }
    .form{
        width: 360px;
        height: 379px;
        background-color: #ffffff;
        border-top:1px solid #fff;
        border-radius: 4px;
        position: relative;
        top:-420px;
        left: 600px;
    }
    .formHeard{
        width: 100%;
        height:21px;
        margin: 50px auto 8px auto;
        font-family: MicrosoftYaHei-Bold;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #44475d;
    }
    .blue{
        width: 80px;
        height: 3px;
        margin: 0 auto 28px auto;
        background-color: #0ccec3;
    }
    .myInput{
        width: 260px;
        height: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        margin-top: 10px;
    }
    .myInput1{
        margin-top: 20px;
    }
    .loginBtn{
        width: 260px;
        height: 40px;
        background-color: #0ccec3;
        margin: 40px auto 0 auto;
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 4px;
        color: #ffffff;
        cursor: pointer;
    }
    .loginBtn:hover{
        background-color: #0bc3b9;
    }
    .loginBtn1{
        height: auto;
        margin: 26px auto 0 auto;
        background-color: #fff;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #44475d;
    }
    .left{
        float: @left;
        cursor: pointer;
    }
</style>
