import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import common from './utils/common.js'
import iView from 'view-design'
import 'view-design/dist/styles/iview.css' 
import moment from 'moment'
import './assets/global.css'
import less from 'less'
import './myStyle/index.less';
import echarts from 'echarts';
import {post,get} from './utils/http'
Vue.prototype.$post=post;
Vue.prototype.$get=get;
Vue.prototype.common = common
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.use(router)
Vue.use(iView)
Vue.use(less)
/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	store,  
	render: h => h(App),
		template: '<App/>',
		data: {
			eventHub: new Vue()
		}
}).$mount('#app')
