import getTokens from './getToken'
export default{
    
    tokenIsTrue: function (vm,error) {
        if(JSON.parse(JSON.stringify(error)).message.indexOf('401') != -1){
            if(vm.$store.state.getToken){
                this.delete('token')
                vm.$store.dispatch('actionTemp',false)
                if(this.get('reToken')){
                    getTokens(vm)
                }else{
                    vm.$Message.warning('登录超时，请重新登录！');
                    vm.$router.push({
                        path: '/'
                    });
                    vm.delete('reToken');
                    vm.delete('expiresin');
                    vm.delete('username');
                }
            }
        }else{
            vm.$Message.error(error.response.data.msg);
        }
    },

    set: function (name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        window.document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    },
    
    get: function (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    },

    delete: function (name) {
        this.set(name, '', -1);
    }
}