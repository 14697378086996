/*
 * 配置编译环境和线上环境之间的切换
 * 以及静态变量
 * baseUrl: 域名地址
 * routerMode: 路由模式
 */
let baseUrl = '';

// console.log('process.env.NODE_ENV',process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') { //测试环境地址
    baseUrl = "http://192.168.20.153:9101";
    // baseUrl=''
    // DEBUG = true;
}else if(process.env.NODE_ENV === 'production'){  //生产环境地址
    // baseUrl = " http://192.168.20.153:9100";   //测试地址
    baseUrl=''
    // baseUrl = "http://192.168.4.8:81";   //生产地址
    // DEBUG = false;
}else if(process.env.NODE_ENV === 'testing'){  //测试环境地址
    baseUrl = "协议名://域名:端口号";   //测试地址
    // DEBUG = false;
}

export default baseUrl
