<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true,
				deviceWidth:'',
			}
		},
		mounted () {
			document.documentElement.style.fontSize = document.documentElement.clientWidth / 1920*100 + 'px';
			let that = this
			if (window.addEventListener) {
				window.addEventListener('resize', function () {
					that.setHtmlFontSize()
				}, false)
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false
				this.$nextTick(() => this.isRouterAlive = true)
			},
			setHtmlFontSize () {
				document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + document.documentElement.clientWidth / 19.2 + 'px !important'
			}
		},
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 0;
	}
</style>
