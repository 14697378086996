const URL = {
    LOGIN:'/app/oauth/token',                                           //登录
    GET_REGISTER_CODE:"/app/auth/bUser/getRegisteredCode",              //忘记密码获取验证码
    CHECK_CODE:'/prepay/auth/sysUser/checkCode',                        //验证验证码
    PHONE_CHANGE_PASSWORD:'/app/auth/bUser/mobileUpdatePassword',       //手机号修改密码
    
    GET_USER_INFO:'/app/auth/bUser/getCurrentUser',                     //获取当前登录的用户信息
    GET_NEW_CODE:'/prepay/auth/sysUser/getNewCode',                     //获取验证码
    REGISTER:'/prepay/auth/sysUser/register',                           //注册
    RESET_PASSWORD:'/prepay/auth/sysUser/resetPassword',                //用户重置密码

    INDEX_DATE:'/app/auth/bUser/getBalance',                            //首页获取各种余额

    WECHAT_PAY:'/app/ops/opsOrderInfo/pay',                             //微信支付
    PAY_RETURN:'/app/ops/opsOrderInfo/getResult',                       //支付回调

    REPORT_REPAIR:'/app/auth/opsRepairInfo/create',                     //新增故障报修
    USER_MODIFY:'/app/baseinfo/bInvoiceInfo/modify',                    //修改资料
    GET_DETAIL:'/app/baseinfo/bInvoiceInfo/detail',                     //获取资料
    UPLOAD_IMG:'/app/auth/img/upload',                                  //图片上传

    REPORT_LIST:'/app/auth/opsRepairInfo/getPage',                      //故障报修列表
    IN_COME:'/app/auth/opsAccountIncome/getPage',                       //充值记录列表
    UN_USUAL:'/app/auth/opsAccountIncome/getUnusual',                   //充值异常列表
    RECHARGE:'/app/auth/opsAccountIncome/recharge',                     //异常转冲
    ENERGY_REPORT:'/app/auth/report/energyReport',                      //用电量echarts
    ENERGY_LIST:'/app/auth/report/energyReportForTime',                 //用电量列表

    USER_MODIFY_PASSWORD:'/app/auth/bUser/updatePassword',              //修改密码

}

module.exports = URL