import url from "./api.js"
import axios from 'axios';

export default function getToken(vm){
    if(vm.common.get('reToken')){
        axios.defaults.headers['Authorization'] = 'Basic YXBwOmFwcDEyMw==';
        axios.post(url.LOGIN+'?grant_type=refresh_token'+'&refresh_token='+vm.common.get('reToken')).then(res => {
            vm.$store.dispatch('actionTemp',true)
            if(res.status == 200){
                vm.common.set('token', res.data['access_token']);
                vm.common.set('reToken', res.data['refresh_token']);
                vm.common.set('expiresin', res.data['expires_in']);
                // vm.common.set('username', res.data['username']);
            }
            vm.$Message.warning('token失效正在为你重新获取token!');
            vm.$router.go(0);
        }).catch(() => {
            vm.$Message.warning('登录超时，请重新登录！');
            vm.$router.push({
                path: '/'
            });
            vm.common.delete('token');
            vm.common.delete('reToken');
            vm.common.delete('expiresin');
            vm.common.delete('username');
        });
    }
}